video {
    max-width: 85vw !important;
}

/* @media (min-width: 576px) { 
    video {
        max-width: 70vw !important;
    }
 } */

@media (min-width: 960px) {
    .oculus-video video {
        max-width: 800px !important;
    }
}

@media (min-width: 1440px) { 
    video {
        max-width: 1320px !important;
    }
 }

.ReactModal__Overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.75) !important;
}

.bg-cover {
    cursor: pointer;
}